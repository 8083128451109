import React from 'react';
import { Spin } from 'antd5';
import styled from '@emotion/styled';

const LoaderEl = styled.div`
  width: 100%;
  min-height: 600px;

  justify-content: center;
  align-items: center;
  display: flex;
`;

export default function Loader() {
  return (
    <LoaderEl>
      <Spin size="large" />
    </LoaderEl>
  );
}
