import { navigate } from 'gatsby';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '../reducers/index';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;

export function navigateTo(route: string, options?: any): AppThunk {
  return () => {
    navigate(route, options);
  };
}
