import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import { parse } from 'query-string';

import Loader from '../components/common/loader';
import { navigateTo } from '../state/actions/routing';
import { saveMobileToken } from '../state/actions/auth';

export default function AddAuthToken({ location }: RouteComponentProps<{}>) {
  const qs = location ? parse(location.search) : { token: '' };

  const token: string = qs['token'] as string;

  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      dispatch(navigateTo('/myhsf/dashboard'));
    } else if (!isSubmitted) {
      setIsSubmitted(true);
      dispatch(saveMobileToken(token));
    }
  }, [token, isSubmitted, dispatch]);

  return <Loader />;
}
